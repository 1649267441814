
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { TransferDetail, AssetList, AreaTree } from '@/types/assetScheduling'
import { ProjectUser } from '@/types/project'
import Dialog from '@/components/assetScheduling/Dialog.vue'

@Component({
  name: 'TransferAdd',
  components: { Dialog }
})
export default class ScheduleAdd extends Vue {
  private showDialog = false
  private areaList: AreaTree[] = []
  private info: TransferDetail = {
    orderDate: '',
    projectId: '',
    remarks: '',
    assetList: [],
    outProjectId: '',
    putCustodianId: '',
    outUserId: '',
    putLocationId: '',
    putUserId: ''
  }

  private props1 = {
    children: 'childNode',
    label: 'assetLocationName',
    value: 'assetLocation'
  }

  private projectUserList: ProjectUser[] = []
  private projectList: ProjectUser[] = []
  private putCustomList: ProjectUser[] = []
  private outCustomList: ProjectUser[] = []
  private projectListAll = []

  private rules = {
    projectId: [
      { required: true, message: '请选择调入项目', trigger: ['blur', 'change'] }
    ],
    putCustodianId: [
      { required: true, message: '请选择调入保管员', trigger: ['blur', 'change'] }
    ],
    putLocationId: [
      { required: true, message: '请选择调入存放位置', trigger: ['blur', 'change'] }
    ],
    putUserId: [
      { required: true, message: '请选择调入方主管', trigger: ['blur', 'change'] }
    ],
    outProjectId: [
      { required: true, message: '请选择调出项目', trigger: ['blur', 'change'] }
    ],
    outUserId: [
      { required: true, message: '请选择调出方主管', trigger: ['blur', 'change'] }
    ]
  }

  private tips = '仅处于空闲状态的资产才可调拨，如果调拨资产非空闲，请联系保管员进行资产更新'

  get assetCode () {
    return this.$route.params.assetCode
  }

  get useId () {
    const userInfo = window.sessionStorage.getItem('userInfo') || ''
    return userInfo ? JSON.parse(userInfo).userId : ''
  }

  created () {
    this.info.outProjectId = this.$route.params.projectId as string || ''
    this.getAreaList()
    this.getProjectList()
    this.getProjectAllByList()
    if (this.info.outProjectId) {
      this.getOutCustomList()
    }
    this.assetCode && this.getOnlyTable()
  }

  // 查询该用户下的所有能管理的项目列表
  getProjectAllByList () {
    this.$axios.get(this.$apis.project.selectProjectAllByList).then((res) => {
      this.projectListAll = res.list || []
    })
  }

  // 查询该用户下的所有能管理的项目列表
  getProjectList () {
    this.info.assetList = []
    this.$axios.get(this.$apis.project.selectMangeProjectByList, {
      userId: this.useId
    }).then((res) => {
      this.projectList = res || []
    })
  }

  // 获取调入主管信息
  getPutCustomList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetTransferListByProjectId, {
      projectId: this.info.projectId,
      nodeCode: '1'
    }).then((res) => {
      this.putCustomList = res || []
    })
  }

  // 获取调出主管信息
  getOutCustomList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetTransferListByProjectId, {
      projectId: this.info.outProjectId,
      nodeCode: '2'
    }).then((res) => {
      this.outCustomList = res || []
    })
  }

  changePutProject () {
    this.getProjectUserList()
    this.info.putCustodianId = ''
    this.getPutCustomList()
  }

  // 获取项目下所有人信息
  getProjectUserList () {
    this.$axios.get(this.$apis.project.selectProjectUserByList, {
      projectId: this.info.projectId
    }).then((res) => {
      this.projectUserList = res.list || []
    })
  }

  // 改变调出方项目
  changeOutProject () {
    this.info.assetList = []
    this.info.outUserId = ''
    this.getOutCustomList()
  }

  // 位置查询二级类型
  getAreaList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetLocationByList, {
      level: 2
    }).then(res => {
      this.areaList = res || []
    })
  }

  onAdd () {
    if (!this.info.outProjectId) {
      this.$message.warning('请先选择调出项目！')
      return false
    }
    this.showDialog = true
    ;(this.$refs.dialog as any).toggleSelection(this.info.assetList)
  }

  success (val: AssetList[]) {
    this.info.assetList = val
  }

  onDelete (index: number) {
    this.info.assetList.splice(index, 1)
  }

  submit (callback: Function) {
    ;(this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        if (this.info.assetList.length === 0) {
          this.$message.warning('请先选择要调拨的资产！')
          return false
        }
        if (this.info.outProjectId === this.info.projectId) {
          this.$message.warning('调入项目和调出项目不能为同一个项目！')
          return false
        }
        this.$axios.post(this.$apis.assetScheduling.insertAssetAllocate, {
          ...this.info
        }).then(() => {
          this.$message.success('保存成功')
          callback()
        })
      }
    })
  }

  onSubmit () {
    this.submit(() => {
      this.$router.back()
    })
  }

  // 获取本条信息
  getOnlyTable () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetByPage, {
      assetCode: this.assetCode
    }).then(res => {
      this.info.assetList = res.list || []
    })
  }
}
